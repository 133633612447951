body {
    margin: 0 !important;
    user-select: none;
}

html,
body,
#root {
    height: 100%;
}

#root {    
    display: flex;
    flex-direction: column;
    height: 100%;
}

#root > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.MuiDataGrid-cell {
    outline: none !important;
}
